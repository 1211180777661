import React from 'react';
//import { Link } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

import Header from '../components/header';
import Footer from '../components/footer';

function About() {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const { t, i18n } = useTranslation();

    return (
        <>
            <Header />
            {
                isMobile
                    ?
                    <ReactBootstrap.Container fluid={true} id="home-mobile">
                        <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="design--main--headline">
                                    {/*
                                        t('Title')
                                    */}
                                </div>
                            </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                    </ReactBootstrap.Container>
                    :
                    <ReactBootstrap.Container fluid={true} id="home">
                        <ReactBootstrap.Row>
                            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="design--main--headline">
                                    {/*
                                        t('Title')
                                    */}
                                </div>
                            </ReactBootstrap.Col>
                        </ReactBootstrap.Row>
                    </ReactBootstrap.Container>
            }
            <ReactBootstrap.Container id="about">
                <ReactBootstrap.Row>
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="design--main--content">
                            <div className="title-text">
                                {
                                    t('about')
                                }
                            </div>
                            <div>
                                {
                                    t('about-one')
                                }
                            </div>
                            <div>
                                {
                                    t('about-two')
                                }
                            </div>
                        </div>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>
            </ReactBootstrap.Container>
            <Footer />
        </>
    );
}

export default About;
