import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Title": "Desktop-as-a-Service without borders",
      "Welcome": "Soon you will find here information about DESIGN, a novel Desktop-as-a-Service solution with unique features that adapts to your needs.",
      "Funded": "DESIGN is a joint development of Nuromedia and Frankfurt University of Applied Sciences, funded by the Federal Ministry for Economic Affairs and Climate Action",
      "Error-Title": "Page not available",
      "Error-text-one": "This page does not exist or is not more available.",
      "Error-text-two": "Check the URL or click on the link on the bottom.",
      "language": "Language",
      "back-link": "Back",
      "about-nav": "About",
      "technology-nav": "Technology",
      "about": "About",
      "about-one": "DESIGN is a new Desktop-as-a-Service (DaaS) with revolutionary features. Our solution has the potential to take the location-independent use of applications for Linux and Windows operating systems to a new level of flexibility and convenience never seen before. DESIGN allows the integration of unmodified desktop applications and enables their use from any client device anywhere in the world. The complete interaction with the service and the applications is done through the browser. The basis of DESIGN is stable and free software components and established protocols. DESIGN is not just a new DaaS but has the potential to revolutionize desktop computing and IT in general.",
      "about-two": "DESIGN is a joint development of Nuromedia and Frankfurt University of Applied Sciences, funded by the Federal Ministry for Economic Affairs and Climate Action. The service has been in development since 2022, with a release planned for 2024.",
      "technology": "Technology",
      "technology-one": "Via a self-developed Web UI and Control Application, DESIGN allows users to interact with all components of the DaaS. It enables the users to access all features of the novel solution, including the desktop applications. In contrast to other solutions, DESIGN does not aim to provide several additional desktops and distract the users with additional complexity but to simplify the work by just exporting the application windows in separate browser tabs. The Web UI and Control Application is also the tool of choice when importing Linux and Windows applications.",
      "technology-two": "Applications are wrapped into Linux Contains or Windows virtual machines to run them in an isolated way, guaranteeing a high level of security by avoiding side effects with other applications. DESIGN uses Apache Guacamole to mediate between the established protocols VNC or RDP for remote access and the browser tabs of the users. The orchestration of the containers and virtual machines is done via the standard solutions Kubernetes and Proxmox based on Debian stable.",
    }
  },
  de: {
    translation: {
      "Title": "Desktop-as-a-Service ohne Grenzen",
      "Welcome": "In naher Zukunft finden Sie hier Informationen zu DESIGN, einer neuartigen Desktop-as-a-Service Lösung mit einzigartigen Features, die sich Ihren Anforderungen anpasst.",
      "Funded": "DESIGN ist eine gemeinsame Entwicklung von Nuromedia und der Frankfurt University of Applied Sciences, gefördert vom Bundesministerium für Wirtschaft und Klimaschutz",
      "Error-Title": "Seite nicht gefunden",
      "Error-text-one": "Diese Seite existiert nicht oder ist nicht mehr verfügbar.",
      "Error-text-two": "Überprüfe die URL oder klicke auf den Link unten.",
      "language": "Sprache",
      "back-link": "Zurück",
      "about-nav": "Über",
      "technology-nav": "Technologie",
      "about": "Über",
      "about-one": "DESIGN ist eine neuer Desktop-as-a-Service (DaaS) mit revolutionären Eigenschaften. Unsere Lösung hat das Potential, die ortsunabhängige Nutzung von Anwendungen für Linux- und Windows-Betriebssysteme auf ein bislang unbekanntes Level hinsichtlich Flexibilität und Komfort zu heben. DESIGN ermöglicht die Integration von unveränderten Desktop-Anwendungen und ermöglicht deren Nutzung von beliebigen Client-Geräten von jedem Ort der Welt aus. Die komplette Interaktion mit dem Dienst und den Anwendungen erfolgt über den Browser. Die Basis von DESIGN sind stabile und freie Softwarekomponenten und etablierte Protokolle. DESIGN ist nicht einfach nur ein neuer DaaS, sondern hat das Potenzial, das Desktop-Computing und die IT insgesamt zu revolutionieren.",
      "about-two": "DESIGN ist eine gemeinsame Entwicklung von Nuromedia und der Frankfurt University of Applied Sciences, gefördert vom Bundesministerium für Wirtschaft und Klimaschutz. Der Dienst ist seit 2022 in Entwicklung. Eine Veröffentlichung ist für 2024 geplant.",
      "technology": "Technische Grundlagen",
      "technology-one": "Über eine selbst entwickelte Web-Oberfläche und Steueranwendung können die Benutzer von DESIGN mit allen Komponenten des DaaS interagieren. Sie ermöglicht den Nutzern den Zugriff auf alle Funktionen der neuartigen Lösung, einschließlich der Desktop-Anwendungen. Im Gegensatz zu anderen Lösungen zielt DESIGN nicht darauf ab, mehrere zusätzliche Desktops zur Verfügung zu stellen und die Benutzer mit zusätzlicher Komplexität abzulenken, sondern die Arbeit zu vereinfachen, indem lediglich die Anwendungsfenster in separate Browser-Tabs exportiert werden. Die Web-Oberfläche und Steueranwendung ist auch das Werkzeug der Wahl zum Import von Linux- und Windows-Anwendungen.",
      "technology-two": "Die Anwendungen werden in Linux-Containern oder virtuellen Windows-Maschinen verpackt, um sie isoliert auszuführen und so ein hohes Maß an Sicherheit zu gewährleisten, indem Seiteneffekte mit anderen Anwendungen vermieden werden. DESIGN verwendet Apache Guacamole, um zwischen den etablierten Protokollen VNC oder RDP für den Fernzugriff und den Browser-Tabs der Benutzer zu vermitteln. Die Orchestrierung der Container und virtuellen Maschinen erfolgt über die Standardlösungen Kubernetes und Proxmox auf Basis von Debian Stable.",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
