import React, {useEffect} from 'react';
import './i18n';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './views/home';
import About from './views/about';
import Technology from './views/technology';
import Error from './views/error';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(localStorage.getItem("language") === "de"){
      i18n.changeLanguage("de")
    } else if(localStorage.getItem("language") === "en"){
      i18n.changeLanguage("en")
    } else {
      i18n.changeLanguage("en")
    }
  }, []);

  return (
    // Setting of the router and their redirections
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/technology" element={<Technology />} />
        <Route path="*" element={<Error />}/>
      </Routes>
    </Router>
  );
}

export default App;

