import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Logo from './../assets/images/png/DESIGNLogoColouredWhiteFont.png';

function Header(){
  const { t, i18n } = useTranslation();

  const changeLanguageGerman = () =>
  {
    i18n.changeLanguage("de")
    localStorage.setItem("language", "de")
  }

  const changeLanguageEnglish = () =>
  {
    i18n.changeLanguage("en")
    localStorage.setItem("language", "en")
  }

  return (
    <>
      <ReactBootstrap.Navbar expand="lg" fixed="top" variant="dark">
        <ReactBootstrap.Container>
          <ReactBootstrap.Navbar.Brand href={"/"}>
            <img src={Logo} alt="Logo"/>
          </ReactBootstrap.Navbar.Brand>
          <ReactBootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
          <ReactBootstrap.Navbar.Collapse id="basic-navbar-nav">
            <ReactBootstrap.Nav className="justify-content-end" style={{ width: "100%" }}>
              <ReactBootstrap.Nav.Link href={"/about"}>{t('about-nav')}</ReactBootstrap.Nav.Link>
              <ReactBootstrap.Nav.Link href={"/technology"}>{t('technology-nav')}</ReactBootstrap.Nav.Link>
              <ReactBootstrap.NavDropdown title={t('language')} id="basic-nav-dropdown">
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageEnglish}>
                  EN
                </ReactBootstrap.NavDropdown.Item>
                <ReactBootstrap.NavDropdown.Item href="#" onClick={changeLanguageGerman}>
                  DE
                </ReactBootstrap.NavDropdown.Item>
              </ReactBootstrap.NavDropdown>
            </ReactBootstrap.Nav>
          </ReactBootstrap.Navbar.Collapse>
        </ReactBootstrap.Container>
      </ReactBootstrap.Navbar>
    </>
  )
}

export default Header;
